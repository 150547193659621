import CustomButton from "./CustomButton";

const BuyButton = ({
  isSoldOut = false,
  isComingSoon = false,
  tncChecked = false,
  paperLink = "",
  isPublicSale = false,
}) => {
  if (isSoldOut) {
    return <CustomButton href={""} fullWidth isDisabled text={"CLOSED"} />;
  }

  if (isComingSoon) {
    return <CustomButton href={""} fullWidth isDisabled text={"COMING SOON"} />;
  }

  return (
    <CustomButton
      href={tncChecked ? paperLink : ""}
      fullWidth
      isDisabled={!tncChecked}
      text={isPublicSale ? "buy now" : "BUY NOW (Allowlist Only)"}
    />
  );
};

export default BuyButton;
