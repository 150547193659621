import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import ContactUsView from "../Components/ContactUs";

export default function ContactUs () {
  return (

    <div className="App">
      <Header />
      <ContactUsView />
      <Footer />
      </div>
  )
}

