/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import Styles from "./Sloggu.module.css";
import MedusaStyles from "./Medusa.module.css";
import Carousel from "../Components/Carousel";
import { Stage } from "../Constants/global";
import { getTotalSupply } from "../Helpers/web3";
import { getItem, isStringNumeric } from "../Helpers/utils";
import PurchaseSuccessfulModal from "../Components/Modals/PurchaseSuccessfulModal";
import Countdown from "../Components/Countdown";
import TermsAndConditionsCheckbox from "../Components/UI/TermsAndConditionsCheckbox";
import { Assets } from "../Assets";
import ProductImagesModal from "../Components/Modals/ProductImagesModal";
import PhysicalFigurine from "../Components/ProductDetail/PhysicalFigurine";
import { RYOGO } from "../Constants/ryogo/constants";
import CustomButton from "../Components/UI/CustomButton";
import BuyButton from "../Components/UI/BuyButton";
import NftDetails from "../Components/UI/NftDetails";

const {
  reserved,
  date,
  maxSupply,
  title,
  hasQuantitySelector,
  presaleLink,
  publicSaleLink,
  preSalePrice,
  maxPerWallet,
  publicPrice,
  stage,
  abi,
  contractAddress,
  artist,
  exclusiveRights,
  nftImages,
} = RYOGO;

const Ryogo = () => {
  const isComingSoon = stage === Stage.ComingSoon;
  const isSoldOut = stage === Stage.SoldOut;
  const isAvailable = stage === Stage.Available;

  const [quantity, setQuantity] = useState(1);
  const initialStateMinted = getItem(`minted_${title}`);
  const [mintedString, setMinted] = useState(
    isSoldOut ? maxSupply : initialStateMinted
  );
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hasClickedOnImage, setHasClickedOnImage] = useState(false);
  const [tncChecked, setTncChecked] = useState(false);
  const [searchParams] = useSearchParams();

  const minted = Number(mintedString);
  const available = maxSupply - minted - reserved;
  const showCountdown = new Date(date) > new Date() && isAvailable;
  const isPublicSale = !showCountdown && isAvailable;
  const price = isPublicSale ? publicPrice : preSalePrice;
  const paperLink = isPublicSale ? publicSaleLink : presaleLink;

  const getState = () => {
    if (minted === maxSupply || isSoldOut) {
      return "SOLD OUT";
    }

    if (isComingSoon) {
      return "COMING SOON";
    }

    return "MINT NOW";
  };

  const state = getState();

  useEffect(() => {
    fetchDataFromSmartContract();
  }, []);

  useEffect(() => {
    const showSuccessModal = searchParams.get("success") === "true";
    setShowSuccessModal(showSuccessModal);
  }, [searchParams]);

  const fetchDataFromSmartContract = () => {
    if (state === "MINT NOW") {
      getTotalSupply(abi, contractAddress).then((minted) => {
        if (isStringNumeric(minted)) {
          setMinted(minted);
          window.localStorage.setItem(`minted_${title}`, minted);
        }
      });
    }
  };

  const changeQuantity = (change: number) => {
    setQuantity((value) => {
      const MIN = 1;
      const MAX = maxPerWallet;

      if ((value <= MIN && change < 0) || (value >= MAX && change > 0)) {
        return value;
      }

      return value + change;
    });
  };

  const Description = ({ text = "", hasLargePaddingTop = false }) => (
    <p
      className={`text-[#DCDFE6] text-[16px] font-normal font-[Maax-Regular] leading-[22px] ${
        hasLargePaddingTop ? "pt-[20px] md:pt-[30px]" : "pt-[9px]"
      }
     `}
    >
      {text}
    </p>
  );

  const DetailImage = ({ src = "" }) => (
    <>
      <img src={src} alt="Figurine detail" height={80} width={80} />
      <div className="mb-[8px]" />
    </>
  );

  const DetailImageMobile = ({ src = "" }) => (
    <img
      src={src}
      alt="Product Detail"
      height={128}
      width={128}
      className={Styles["nft-image"]}
      onClick={() => setHasClickedOnImage(true)}
    />
  );

  const Card = ({
    title = "",
    date = "",
    bg = "",
    color = "",
    subtitle = "",
    bulletPoints = [""],
  }) => (
    <div
      className={`p-[24px] w-full sm:w-[304px] h-[242px] lg:h-[252px] ${bg} rounded-[10px] text-[${color}] font-[Maax-Bold] leading-[22px] mt-[24px] sm:mt-0`}
    >
      <p className="text-[18px]">{title}</p>
      <p className="text-[18px]">{date}</p>
      <div className="h-[1px] bg-[#353E4B] w-full mt-[15px] mb-[15px]" />
      <p className="text-[14px]">{subtitle}</p>
      {bulletPoints.map((value, index) => (
        <p className="text-[14px] font-[Maax-Regular]" key={index.toString()}>
          - {value}
        </p>
      ))}
    </div>
  );

  const Arrows = () => {
    const arrowSize = 7;

    return (
      <div className="hidden lg:flex flex-row items-center xl:pl-[2.8%] xl:pr-[2.8%] ">
        <img
          src={Assets.transparentRightArrow}
          alt="Right arrow"
          height={arrowSize}
          width={arrowSize}
          className="mr-[12px]"
        />
        <img
          src={Assets.transparentRightArrow}
          alt="Right arrow"
          height={arrowSize}
          width={arrowSize}
          className="mr-[12px]"
        />
        <img
          src={Assets.transparentRightArrow}
          alt="Right arrow"
          height={arrowSize}
          width={arrowSize}
        />
      </div>
    );
  };

  return (
    <div className="App bg-[#000000]">
      <Header />
      {showCountdown ? <Countdown date={date} /> : null}
      <div className={`pb-[65px] ${MedusaStyles["container"]}`}>
        <div className={Styles["left-subcontainer"]}>
          <Carousel assetList={nftImages} />
        </div>

        <div className={MedusaStyles["right-subcontainer"]}>
          <p className="text-[#707682] mb-[14px] font-[Maax-Bold]">
            Mighty Jaxx
          </p>
          <p className="text-[#FFFFFF] text-[36px] font-[Maax-Bold] leading-[44px]">
            {title}
          </p>

          <div className="mb-[40px]">
            <Description
              hasLargePaddingTop
              text={`For one night, the mischievous creatures of the Monster Realm enter the human world. Marching across Japan, the hidden, camera-shy bunch pulls all sorts of pranks! Take this once-in-a-lifetime opportunity and snap a picture of them by minting the NFT—you might even learn more about them!`}
            />
          </div>

          <NftDetails
            isSoldOut={isSoldOut}
            available={available}
            maxSupply={maxSupply}
            artist={artist}
            exclusiveRights={exclusiveRights}
          />

          {isComingSoon || !hasQuantitySelector ? null : (
            <div className={Styles["nft-details-bottom-container"]}>
              <p className={Styles["gray-text"]}>QUANTITY</p>
              <div className="flex flex-row items-center border-[#22252E] border-[1px] p-[4px] pl-[18px] pr-[18px] text-[17px] text-[#FFFFFF]">
                <div
                  className="text-[30px] pr-[17px] mt-[1px] font-normal cursor-pointer"
                  onClick={() => changeQuantity(-1)}
                >
                  <p>-</p>
                </div>
                <p className="pr-[17px]">{quantity}</p>
                <div
                  className="text-[25px] font-normal mb-[2px] cursor-pointer"
                  onClick={() => changeQuantity(1)}
                >
                  <p>+</p>
                </div>
              </div>
            </div>
          )}
          <div className={Styles["nft-details-bottom-container"]}>
            <p className={Styles["gray-text"]}>PRICE</p>
            <div className="flex justify-center items-center ">
              <p className="text-[22px] text-[#EFEFEF] font-[Maax-Bold]">
                ${price * quantity} USDC*{" "}
              </p>

              <span className="inline-flex justify-center items-center bg-[#353E4B] rounded-[12px] w-[24px] h-[24px] text-center ml-[7px] mb-[5px] tooltip">
                <p className="text-[16px] text-white mt-[2px]">?</p>
                <span className="tooltiptext">
                  You can pay by Credit Card, Apple Pay(Safari), Google
                  Pay(Chrome) or Ethereum upon checkout
                </span>
              </span>
            </div>
          </div>

          {isAvailable ? (
            <TermsAndConditionsCheckbox
              checked={tncChecked}
              onChange={() => setTncChecked(!tncChecked)}
            />
          ) : null}

          <BuyButton
            isSoldOut={isSoldOut}
            isComingSoon={isComingSoon}
            tncChecked={tncChecked}
            paperLink={paperLink}
            isPublicSale={isPublicSale}
          />
        </div>
      </div>

      <div
        className={`mt-[20px] sm:mt-[60px] pb-[50px] desktop:pb-0 flex flex-col justify-center items-center bg-[#000000] w-full text-left ${MedusaStyles["h-padding"]}`}
      >
        <div
          className={
            "flex flex-col sm:justify-center sm:items-center w-full sm:w-fit max-w-[1366px]"
          }
        >
          <p className="text-white font-[Maax-Bold] text-[32px] text-left self-start self">
            Ryogo Toyoda
            <br /> Product Roadmap
          </p>

          <div className="mt-[15px] lg:mt-[45px] lg:mb-[153px] flex flex-col sm:grid sm:grid-cols-2 sm:gap-[16px] lg:flex lg:flex-row">
            <>
              <Card
                title="Ryogo Drop 1"
                date="(28th October)"
                subtitle="22 - 29 Oct"
                bulletPoints={[
                  "Free Redemption of Phygital Frame",
                  "Airdrops for the Easter Egg NFT",
                ]}
                bg="bg-[#13161A]"
                color="#DCDFE6"
              />
              <Arrows />
            </>

            <>
              <Card
                title="Ryogo Drop 2"
                date="(November)"
                subtitle="December Utility"
                bulletPoints={[
                  "Free Redemption of Phygital Frame",
                  "Airdrops for the Easter Egg NFT",
                ]}
                bg="bg-[#292D38]"
                color="#DCDFE6"
              />
              <Arrows />
            </>

            <Card
              title="Ryogo Drop 3"
              date="(December)"
              subtitle="January Utility"
              bulletPoints={[
                "Free Redemption of Phygital Frame",
                "Airdrops for the Easter Egg NFT",
                "Community Vote",
                "Toy drop (Q1 2023)",
              ]}
              bg="bg-[#F7F7F7]"
              color="#000000"
            />
          </div>
        </div>
      </div>

      <div className={`w-full flex justify-center `}>
        <div className={`${MedusaStyles["container-figurine"]}`}>
          <div
            className={`flex flex-col desktop:flex-row justify-center desktop:justify-start  items-center desktop:ml-[1%] desktop:mt-[20px] mobile:mb-[120px] mb-[15px] desktop:max-w-[600px]`}
          >
            <button
              className={`flex-col absolute mb-[40px] hidden desktop:flex`}
              onClick={() => setHasClickedOnImage(true)}
            >
              <DetailImage src={Assets.medusaEnlargedDetail1} />
              <DetailImage src={Assets.medusaEnlargedDetail2} />
              <DetailImage src={Assets.medusaEnlargedDetail3} />
            </button>

            <PhysicalFigurine />

            <div
              className={`hidden ${MedusaStyles["carousel-container-mobile"]}`}
            >
              <DetailImageMobile src={Assets.medusaEnlargedDetail1} />
              <DetailImageMobile src={Assets.medusaEnlargedDetail2} />
              <DetailImageMobile src={Assets.medusaEnlargedDetail3} />
            </div>
          </div>

          <div
            className={`desktop:max-w-[400px] ${MedusaStyles["right-subcontainer"]}`}
          >
            <p className="text-[#FFFFFF] text-[32px] font-[Maax-Bold] leading-[38px]">
              PHYGITAL FRAME <br /> COMING SOON
            </p>

            <div className="mb-[28px]">
              <Description
                hasLargePaddingTop
                text={`The truth is that images of monsters in the Yokai Parade can never exist physically. Yet, we’ve made the impossible, possible! All NFT holders will receive an exclusive PHYSICAL FRAME of the monsters—a specially crafted talisman that will retain their pictures so you can proudly display them on your shelf. The more you mint, the more monsters you’ll get!`}
              />
            </div>

            <div className="ml-[10px] mb-[40px]">
              <Description
                text={`• 8.7" Acrylic Digital Frame Limited Edition`}
              />
              <Description text={`• Built-in 1500mAh rechargable battery`} />
              <Description text={`• Powered by Forreal`} />
              <Description text={`• Purchase gated by the NFT`} />
            </div>

            {isSoldOut ? (
              <CustomButton isDisabled={false} text={"available now"} />
            ) : (
              <CustomButton fullWidth isDisabled text={"COMING SOON"} />
            )}
          </div>
        </div>
      </div>

      <Footer />
      <PurchaseSuccessfulModal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
      />
      <ProductImagesModal
        isOpen={hasClickedOnImage}
        onRequestClose={() => setHasClickedOnImage(false)}
      />
    </div>
  );
};

export default Ryogo;
