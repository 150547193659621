import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Sloggu from "./Routes/Sloggu";
import Home from "./Routes/Home";
import Medusa from "./Routes/Medusa";
import { Stage, USE_TESTNET } from "./Constants/global";
import Ryogo from "./Routes/Ryogo";
import Sandbox from "./Routes/Sandbox";
import ContactUs from "./Routes/ContactUs";
import { RYOGO } from "./Constants/ryogo/constants";
import { ChakraProvider } from "@chakra-ui/react";

ReactGA.initialize("G-EFRFFLLH25", {
  testMode: USE_TESTNET,
});

const getLibrary = (
  provider:
    | ethers.providers.ExternalProvider
    | ethers.providers.JsonRpcFetchFunc
) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const App = () => {
  useEffect(() => {
    const page = window.location.pathname + window.location.search;
    ReactGA.send({ hitType: "pageview", page, title: page });
  }, []);

  return (
    <ChakraProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="sloggu" element={<Sloggu />} />
            <Route path="medusa" element={<Medusa />} />
            <Route path="contact-us" element={<ContactUs />} />
            {RYOGO.stage === Stage.Hidden ? null : (
              <Route path="ryogo" element={<Ryogo />} />
            )}
            <Route path="sandbox" element={<Sandbox />} />
          </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </ChakraProvider>
  );
};

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
