const CustomButton = ({
  fullWidth = false,
  isDisabled = false,
  href = "",
  text = "",
}) => {
  if (!href) {
    return (
      <button
        disabled
        className={`flex flex-row items-center justify-center text-[14px] font-[Maax-Bold] pt-[15px] pb-[15px] rounded-md bg-[#353E4B] text-[white] uppercase ${
          fullWidth ? "" : "desktop:max-w-[328px]"
        }`}
      >
        {text}
      </button>
    );
  }

  return (
    <a
      href={href}
      className={`flex flex-row  items-center justify-center text-[14px] font-[Maax-Bold] pt-[16px] pb-[14px] rounded-md w-full uppercase ${
        fullWidth ? "" : "desktop:max-w-[328px]"
      } ${
        !isDisabled
          ? "bg-[#F7F7F7] text-[black"
          : "bg-[#353E4B] text-[white] pointer-events-none"
      } `}
    >
      {text}
    </a>
  );
};

export default CustomButton;
