import { Assets } from "../Assets";
import { MEDUSA, Stage } from "./global";
import { RYOGO } from "./ryogo/constants";

const RecentReleases = [
  RYOGO,
  MEDUSA,
  {
    title: "Sloggu",
    description: "Help them escape by minting them",
    date: "",
    image: Assets.sloggu,
    link: "https://opensea.io/collection/sloggu",
    isRecent: true,
    stage: Stage.SoldOut,
  },
  {
    title: "Inked Stories: Eve (Colorized)",
    description: "A JPK Original 3D NFT on VeVe",
    date: "",
    image: Assets.inked_stories_eve,
    link: "https://medium.com/veve-collectibles/veve-at-designercon-sunday-473f1d6c4a78",
    isRecent: true,
    stage: Stage.SoldOut,
  },
  {
    title: "Nyammy Treats",
    description: "The Nyan Sum Cards Mystery Box Series on Binance",
    date: "",
    image: Assets.nyan_treat,
    link: "https://www.binance.com/en/nft/mystery-box/market?serialNo=119275062141629440",
    isRecent: true,
    stage: Stage.SoldOut,
  },
  {
    title: "The Huntress",
    description: "1 of 1 Phygital NFT Open Auction on OpenSea",
    date: "",
    image: Assets.huntress,
    link: "https://opensea.io/collection/mighty-jaxx-1-of-1-editions",
    isRecent: true,
    stage: Stage.SoldOut,
  },
  {
    title: "Beauty of Rebellion",
    description: "An Abell Octovan Original, Scan To Unlock A Special NFT",
    date: "",
    image: Assets.abel_octovan_beauty_rebellion,
    link: "https://mightyjaxx.com/products/the-beauty-of-rebellion-by-abell-octovan",
    isRecent: true,
    stage: Stage.SoldOut,
  },
];

export default RecentReleases;
