import Web3 from "web3";

import ABI from "../Constants/abi";
import {
  NFT_CONTRACT_ADDRESS,
  USE_TESTNET,
  POLYGON_CHAIN_ID,
  ETH_CHAIN_ID,
  ETH_RPC,
} from "../Constants/global";
import getLinker from "./deepLink";
import { mobileCheck } from "./utils";

const httpProvider = new Web3.providers.HttpProvider(ETH_RPC);

const getProvider = () => {
  return Web3.givenProvider || httpProvider;
};

export const signMessage = (messageToSign: string, account: string) => {
  getProvider()
    .request({
      method: "personal_sign",
      params: [messageToSign, account],
    })
    .then(console.log);
};

export const mintNFT = async (quantity: number, toAddress: string) => {
  const web3 = new Web3(getProvider());
  // @ts-ignore
  const contract = new web3.eth.Contract(ABI, NFT_CONTRACT_ADDRESS);
  let tokenId = 0;

  const transactionObject = await contract.methods
    .mintNFT(String(quantity), toAddress)
    .send({ from: toAddress, gas: web3.utils.toHex(300000) });

  const {
    transactionHash: txHash,
    events: { Transfer },
  } = transactionObject;

  if (Transfer && Transfer?.returnValues) {
    // Transfer is an object
    tokenId = Transfer?.returnValues?.tokenId;
  } else {
    // Transfer is an array
    tokenId = Transfer[Transfer.length - 1].returnValues?.tokenId;
  }

  return { txHash, tokenId };
};

export const getTotalSupply = async (abi: any, contractAddress: string) => {
  try {
    let web3 = new Web3(getProvider());
    const chainId = await web3.eth.net.getId();

    if (chainId !== ETH_CHAIN_ID) {
      web3 = new Web3(httpProvider);
    }

    // @ts-ignore
    const contract = new web3.eth.Contract(abi, contractAddress);
    const supply = await contract.methods.totalSupply().call();

    return supply;
  } catch (err) {
    if (USE_TESTNET) console.log(err);
    return "0";
  }
};

export const balanceOf = async (account: string) => {
  try {
    const web3 = new Web3(getProvider());
    // @ts-ignore
    const contract = new web3.eth.Contract(ABI, NFT_CONTRACT_ADDRESS);
    const balance = await contract.methods.balanceOf(account).call();

    return balance;
  } catch (err) {
    if (USE_TESTNET) console.log(err);
    return "0";
  }
};

export const switchToPolygon = async () => {
  const { ethereum } = window;

  const chainId = `0x${POLYGON_CHAIN_ID.toString(16)}`;
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  } catch (err) {
    let switchError: any = err;

    if (switchError?.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId,
              rpcUrls: [
                USE_TESTNET
                  ? "https://matic-mumbai.chainstacklabs.com"
                  : "https://polygon-rpc.com/",
              ],
              chainName: USE_TESTNET ? "Polygon Testnet" : "Matic Network",
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: [
                USE_TESTNET
                  ? "https://mumbai.polygonscan.com/"
                  : "https://polygonscan.com/",
              ],
            },
          ],
        });
      } catch (addError) {
        console.log(addError);
      }
    } else {
      console.log(switchError);
    }
  }
};

export const onClickMetamask = () => {
  const isMobile = mobileCheck();
  if ((isMobile && !window?.ethereum) || !window?.ethereum?.isMetaMask) {
    // Mobile user
    const downloadMetamaskUrl = "https://metamask.io/download.html";
    const linker = getLinker(downloadMetamaskUrl);
    const deepLink = `https://metamask.app.link/dapp/${window.location.hostname}${window.location.pathname}`;
    linker.openURL(deepLink);
    return;
  }

  const type = "injected";
  window.localStorage.setItem("provider", type);
  return type;
};

export const refreshState = () => {
  window.localStorage.removeItem("provider");
  window.localStorage.removeItem("account");
};
