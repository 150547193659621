import { useEffect } from "react";

const Sandbox = () => {
  useEffect(() => {
    const gleamCampaignLink =
      "https://gleam.io/M7Td7/mightyverse-presents-spooky-season";
    window.location.href = gleamCampaignLink;
  }, []);

  return null;
};

export default Sandbox;
