import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from "@chakra-ui/react";

export default function CustomModal({
  isOpen = false,
  isShowSecondary = false,
  title = "",
  message = "",
  primaryLabel = "",
  secondaryLabel = "",
  primaryColorScheme = "blue",
  secondaryColorScheme = "ghost",
  onClose,
  onClickPrimary,
  onClickSecondary,
}: {
  isOpen: boolean;
  isShowSecondary?: boolean;
  title: string;
  message: string;
  primaryLabel: string;
  secondaryLabel?: string;
  primaryColorScheme?: string;
  secondaryColorScheme?: string;
  onClose: () => void;
  onClickPrimary: () => void;
  onClickSecondary?: () => void;
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="scale">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <SimpleGrid columns={isShowSecondary ? 2 : 1} spacing="10px">
            <Button
              colorScheme={secondaryColorScheme}
              mr={3}
              onClick={onClickSecondary}
            >
              {secondaryLabel}
            </Button>
            <Button variant={primaryColorScheme} onClick={onClickPrimary}>
              {primaryLabel}
            </Button>
          </SimpleGrid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
