import { memo, useState } from "react";
import { Assets } from "../../Assets";
import MedusaStyles from "../../Routes/Medusa.module.css";

const AssetList = [
  Assets.medusaTurnarounds1,
  Assets.medusaTurnarounds2,
  Assets.medusaTurnarounds3,
  Assets.medusaTurnarounds4,
  Assets.medusaTurnarounds5,
  Assets.medusaTurnarounds6,
  Assets.medusaTurnarounds7,
  Assets.medusaTurnarounds8,
];

const PhysicalFigurine = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const ArrowButton = ({ isLeft = false, onClick = () => {} }) => (
    <button
      onClick={onClick}
      className={`flex justify-center items-center bg-[#22252E] h-[32px] w-[32px] rounded-full ${
        isLeft ? "pr-[3px]" : "pl-[3px]"
      }`}
    >
      <div
        className={
          isLeft ? MedusaStyles["arrow-left"] : MedusaStyles["arrow-right"]
        }
      ></div>
    </button>
  );

  const onClickLeft = () => {
    setSelectedImage((value) => {
      if (value === 0) {
        return AssetList.length - 1;
      }

      return value - 1;
    });
  };

  const onClickRight = () => {
    setSelectedImage((value) => {
      if (value === AssetList.length - 1) {
        return 0;
      }

      return value + 1;
    });
  };

  return (
    <div className="flex flex-col items-center desktop:ml-[40px] desktop:pl-[3%] max-w-[400px] desktop:max-w-[580px] desktop:max-h-[545px]">
      {AssetList.map((value, index) => (
        <img
          src={value}
          alt="Medusa Physical Figure"
          className={selectedImage === index ? "" : "hidden"}
          key={index.toString()}
        />
      ))}

      <div className="flex flex-row items-center justify-center mt-[10px]">
        <ArrowButton isLeft onClick={onClickLeft} />
        <p className="pl-[20px] pr-[20px] mt-[3px] text-[14px] leading-[20px] font-[Maax-Bold] text-[#707682]">
          360 VIEW
        </p>
        <ArrowButton onClick={onClickRight} />
      </div>
    </div>
  );
};

export default memo(PhysicalFigurine);
