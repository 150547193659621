import { Assets } from "../../Assets";
import { Stage, USE_TESTNET } from "../global";
import ryogoAbi from "./ryogoAbi.json";

export const RYOGO = (() => {
  const date = "2022-10-7T03:00:00.000Z"; // Oct 7 2022 11:00 (Singapore Time)

  return {
    title: "Monster Parade, Ryogo: Drop 1",
    description:
      "Join the Yokai Parade, where monsters run free on the streets of Japan.",
    link: "/ryogo",
    artist: "Ryogo Toyoda",
    exclusiveRights: "Phygital Frame (22nd Oct)",
    nftImages: [Assets.ryogoHomepageTeaser],
    image: Assets.ryogoHomepageTeaser,
    presaleLink: USE_TESTNET ? "" : "",
    publicSaleLink: USE_TESTNET ? "" : "",
    preSalePrice: 89,
    publicPrice: 99,
    maxSupply: 666,
    maxPerWallet: 666,
    reserved: 0,
    date,
    isRecent: true,
    stage: USE_TESTNET ? Stage.ComingSoon : Stage.Hidden,
    hasQuantitySelector: false,
    abi: ryogoAbi,
    contractAddress: USE_TESTNET ? "" : "",
  };
})();
