import { useState } from "react";
import Carousel, {
  arrowsPlugin,
  CarouselPluginTypes,
  Dots,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";

import "@brainhubeu/react-carousel/lib/style.css";

import CarouselStyles from "./CarouselSlider.module.css";
import Card from "./Card";

import { Assets } from "../../Assets";
import { Stage } from "../../Constants/global";

interface CarouselSliderItem {
  title: string;
  description: string;
  date: string;
  image: string;
  link: string;
  isRecent: boolean;
  stage: Stage;
}

const CarouselSlider = ({
  items: allItems,
}: {
  items: CarouselSliderItem[];
}) => {
  const [currentSlideIndex, setSlideIndex] = useState(0);
  const onChange = (val: number) => setSlideIndex(val);

  const items = allItems.filter((value) => value.stage !== Stage.Hidden);

  const plugins: Array<string | CarouselPluginTypes> = [
    "infinite",
    {
      resolve: slidesToShowPlugin,
      options: { numberOfSlides: items.length < 3 ? items.length : 3 },
    },
    {
      resolve: arrowsPlugin,
      options: {
        arrowLeft: (
          <button className={CarouselStyles["App-carousel-slider-button"]}>
            <img src={Assets.left_arrow} alt="left" />
          </button>
        ),
        arrowRight: (
          <button className={CarouselStyles["App-carousel-slider-button"]}>
            <img src={Assets.right_arrow} alt="right" />
          </button>
        ),
        addArrowClickHandler: true,
      },
    },
  ];

  const responsivePlugins: Array<string | CarouselPluginTypes> = [
    "infinite",
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides: 1,
      },
    },
  ];

  return (
    <>
      <div className={CarouselStyles["App-carousel-slider"]}>
        <Carousel
          plugins={plugins}
          breakpoints={{
            1100: {
              plugins: responsivePlugins,
            },
          }}
          value={currentSlideIndex}
          onChange={onChange}
        >
          {items.map((item, index) => {
            return <Card {...item} key={index.toString()} />;
          })}
        </Carousel>
      </div>
      <Dots
        value={currentSlideIndex}
        onChange={onChange}
        number={items.length}
        className={CarouselStyles["App-carousel-slider-dots"]}
      />
    </>
  );
};

export default CarouselSlider;
