import { Assets } from "../Assets";
import medusaABI from "./medusaABI";

export const MAX_SUPPLY = 5555;
export const MAX_PER_WALLET = 3;
export const RESERVED = 60;

export const USE_TESTNET = !!process.env.REACT_APP_USE_TESTNET;
const HIDE_COUNTDOWN = !!process.env.REACT_APP_HIDE_COUNTDOWN;

export const POLYGON_RPC = USE_TESTNET
  ? `https://polygon-mumbai.g.alchemy.com/v2/heDodAHl-mZlltbtBJ4R9-XvQBqRsDG4`
  : `https://polygon-mainnet.g.alchemy.com/v2/W-ui9fYLoK9OBLZSTSD7xNN0PCD7ezTH`;

export const ETH_RPC = USE_TESTNET
  ? `https://eth-goerli.g.alchemy.com/v2/nEdutmgxOQTwLtaZZzadxzpDIwsGe3C8`
  : `https://eth-mainnet.g.alchemy.com/v2/7qBMr6Y54uOoD-jyv4FdO_BV3hvIqLLz`;

export const NFT_CONTRACT_ADDRESS = USE_TESTNET
  ? "0xef1e2546d1184865acF4815a8AcC9786bf807144"
  : "0x478D9a40f5749eDAb4AA3941dB2596a6dd7315bA";

export const POLYGON_CHAIN_ID = USE_TESTNET ? 80001 : 137;
export const ETH_CHAIN_ID = USE_TESTNET ? 5 : 1;

// Thu Aug 18 2022 22:00:00 GMT+0800 (Singapore Standard Time)
export const MINT_DATE = HIDE_COUNTDOWN ? 0 : 1660831200000;
export const IS_SOLD_OUT = true;

export enum Stage {
  Hidden,
  ComingSoon,
  Available,
  SoldOut,
}

export const PAPER_TESTNET_PRICE = 0.00001;

export const MEDUSA = (() => {
  const date = "2022-09-27T03:00:00.000Z"; // Sep 22 2022 11:00 (Singapore Time)
  const linkDate = "2022-10-22T01:00:00.000Z"; // Oct 22 2022 09:00 (Singapore Time)
  const endSateDate = "2022-11-04T03:00:00.000Z"; // Oct 22 2022 09:00 (Singapore Time)

  return {
    title: "Inked Stories: Medusa",
    description: "A Greek myth comes to life",
    link: "/medusa",
    image: Assets.medusa,
    banner: Assets.medusaBanner,
    bannerMobile: Assets.medusaBannerMobile,
    presaleLink: USE_TESTNET
      ? "https://paper.xyz/checkout/39d99bfe-96d1-4851-b7cc-0d5f95c833a6?metadata=%7B%0A++%22usePaperKey%22%3A+true%0A%7D&mintMethod=eyJuYW1lIjoicHJlc2FsZU1pbnQiLCJhcmdzIjp7InF1YW50aXR5IjoiJFFVQU5USVRZIiwicmVjaXBpZW50IjoiJFdBTExFVCIsIl9ub25jZSI6IiROT05DRSIsIl9zaWduYXR1cmUiOiIkU0lHTkFUVVJFIn0sInBheW1lbnQiOnsiY3VycmVuY3kiOiJFVEgiLCJ2YWx1ZSI6IjAuMDAwMDEgKiAkUVVBTlRJVFkifSwiY2FsbE9wdGlvbnMiOnsiZ2FzT3B0aW9ucyI6Im1lZGl1bSJ9fQ%3D%3D&eligibilityMethod=eyJuYW1lIjoicHJlc2FsZUluZWxpZ2liaWxpdHlSZWFzb24iLCJhcmdzIjp7InJlY2lwaWVudCI6IiRXQUxMRVQiLCJxdWFudGl0eSI6IiRRVUFOVElUWSJ9fQ%3D%3D&usePaperKey=true"
      : "https://paper.xyz/checkout/0a307c19-b710-4d9b-ac70-390ebc1f38ab?mintMethod=eyJuYW1lIjoicHJlc2FsZU1pbnQiLCJhcmdzIjp7InF1YW50aXR5IjoiJFFVQU5USVRZIiwicmVjaXBpZW50IjoiJFdBTExFVCIsIl9ub25jZSI6IiROT05DRSIsIl9zaWduYXR1cmUiOiIkU0lHTkFUVVJFIn0sInBheW1lbnQiOnsiY3VycmVuY3kiOiJVU0RDIiwidmFsdWUiOiI4OSAqICRRVUFOVElUWSJ9LCJjYWxsT3B0aW9ucyI6eyJnYXNPcHRpb25zIjoibWVkaXVtIn19&eligibilityMethod=ewogICJuYW1lIjogInByZXNhbGVJbmVsaWdpYmlsaXR5UmVhc29uIiwKICAiYXJncyI6IHsKICAgICJyZWNpcGllbnQiOiAiJFdBTExFVCIsCiAgICAicXVhbnRpdHkiOiAiJFFVQU5USVRZIgogIH0KfQ%3D%3D&usePaperKey=true",
    publicSaleLink: USE_TESTNET
      ? "https://paper.xyz/checkout/39d99bfe-96d1-4851-b7cc-0d5f95c833a6?metadata=%7B%0A+%22usePaperKey%22%3A+true%0A%7D&mintMethod=eyJuYW1lIjoibWludCIsImFyZ3MiOnsicXVhbnRpdHkiOiIkUVVBTlRJVFkiLCJyZWNpcGllbnQiOiIkV0FMTEVUIiwiX25vbmNlIjoiJE5PTkNFIiwiX3NpZ25hdHVyZSI6IiRTSUdOQVRVUkUifSwicGF5bWVudCI6eyJjdXJyZW5jeSI6IkVUSCIsInZhbHVlIjoiMC4wMDAwMSAqICRRVUFOVElUWSJ9LCJjYWxsT3B0aW9ucyI6eyJnYXNPcHRpb25zIjoibWVkaXVtIn19Cg%3D%3D&eligibilityMethod=eyJuYW1lIjoiaW5lbGlnaWJpbGl0eVJlYXNvbiIsImFyZ3MiOnsicmVjaXBpZW50IjoiJFdBTExFVCIsInF1YW50aXR5IjoiJFFVQU5USVRZIn19&usePaperKey=true"
      : "https://paper.xyz/checkout/0a307c19-b710-4d9b-ac70-390ebc1f38ab?mintMethod=eyJuYW1lIjoibWludCIsImFyZ3MiOnsicXVhbnRpdHkiOiIkUVVBTlRJVFkiLCJyZWNpcGllbnQiOiIkV0FMTEVUIiwiX25vbmNlIjoiJE5PTkNFIiwiX3NpZ25hdHVyZSI6IiRTSUdOQVRVUkUifSwicGF5bWVudCI6eyJjdXJyZW5jeSI6IlVTREMiLCJ2YWx1ZSI6Ijk5ICogJFFVQU5USVRZIn0sImNhbGxPcHRpb25zIjp7Imdhc09wdGlvbnMiOiJtZWRpdW0ifX0%3D&eligibilityMethod=ewogICJuYW1lIjogImluZWxpZ2liaWxpdHlSZWFzb24iLAogICJhcmdzIjogewogICAgInJlY2lwaWVudCI6ICIkV0FMTEVUIiwKICAgICJxdWFudGl0eSI6ICIkUVVBTlRJVFkiCiAgfQp9&usePaperKey=true",
    preSalePrice: 89,
    publicPrice: 99,
    maxSupply: 888,
    maxPerWallet: 800,
    reserved: 0,
    date,
    isRecent: true,
    stage:
      USE_TESTNET || new Date() > new Date(endSateDate)
        ? Stage.SoldOut
        : Stage.Available,
    physicalFigurineLink:
      USE_TESTNET || new Date() > new Date(linkDate)
        ? "https://mightyjaxx.com/products/inked-stories-medusa-ft-jpk"
        : "",
    hasQuantitySelector: false,
    abi: medusaABI,
    contractAddress: USE_TESTNET
      ? "0xdAcea22eFD286050FF8C75a3cCDa4ac0d917bBd7"
      : "0x9e7a90e80676A18F5822918b1D68c44B5Eda3A2E",
  };
})();
