/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import Styles from "./Sloggu.module.css";
import Carousel from "../Components/Carousel";
import {
  MAX_PER_WALLET,
  MAX_SUPPLY,
  MINT_DATE,
  USE_TESTNET,
  RESERVED,
  IS_SOLD_OUT,
  NFT_CONTRACT_ADDRESS,
} from "../Constants/global";
import {
  balanceOf,
  getTotalSupply,
  mintNFT,
  onClickMetamask,
  refreshState,
  switchToPolygon,
} from "../Helpers/web3";
import { getItem, isStringNumeric } from "../Helpers/utils";
import useCountDown from "../hooks/useCountDown";
import { connectors } from "../Helpers/connectors";
import MintSuccessModal from "../Components/Modals/MintSuccessModal";
import MintLimitModal from "../Components/Modals/MintLimitModal";
import ABI from "../Constants/abi";

function Sloggu() {
  const [quantity, setQuantity] = useState(1);
  const initialStateMinted = getItem("minted");
  const initialStateBalance = getItem("balance");
  const [mintedString, setMinted] = useState(
    IS_SOLD_OUT ? MAX_SUPPLY : initialStateMinted
  );
  const [balanceString, setBalance] = useState(initialStateBalance);
  const [txHash, setTxHash] = useState("");
  const [mintWhenConnected, setMintWhenConnected] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
  const [days, hours, minutes, seconds] = useCountDown(MINT_DATE);
  const { deactivate, activate, account } = useWeb3React();

  const minted = Number(mintedString);
  const available = MAX_SUPPLY - minted - RESERVED;
  const polygonScanUrl = `https://${
    USE_TESTNET ? "mumbai." : ""
  }polygonscan.com/tx/${txHash}`;
  const showCountdown = days + hours + minutes + seconds > 0;

  useEffect(() => {
    const activateConnectors = () => {
      const provider = getItem("provider");
      const account = getItem("account");

      if (provider && account) {
        // @ts-ignore
        activate(connectors[provider]);
      }
    };

    activateConnectors();
  }, []);

  useEffect(() => {
    fetchDataFromSmartContract();

    if (account) {
      window.localStorage.setItem("account", account);
    }
  }, [account]);

  const getState = () => {
    if (minted === MAX_SUPPLY) {
      return "SOLD OUT";
    }

    if (showCountdown) {
      return "COMING SOON";
    }

    if (isMinting) {
      return "MINTING...";
    }

    return "MINT NOW";
  };

  useEffect(() => {
    fetchDataFromSmartContract();
  }, []);

  const fetchDataFromSmartContract = () => {
    if (!IS_SOLD_OUT) {
      getTotalSupply(ABI, NFT_CONTRACT_ADDRESS).then((minted) => {
        if (isStringNumeric(minted)) {
          setMinted(minted);
          window.localStorage.setItem("minted", minted);
        }
      });

      if (!account) return;

      balanceOf(account).then((balance) => {
        if (isStringNumeric(balance)) {
          setBalance(balance);
          window.localStorage.setItem("balance", balance);
        }
      });
    }
  };

  const changeQuantity = (change: number) => {
    setQuantity((value) => {
      const MIN = 1;
      const MAX = MAX_PER_WALLET;

      if ((value <= MIN && change < 0) || (value >= MAX && change > 0)) {
        return value;
      }

      return value + change;
    });
  };

  const onClickDisconnect = async () => {
    try {
      refreshState();
      deactivate();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickConnect = async () => {
    try {
      await onClickDisconnect();
      const connector = onClickMetamask();

      if (connector) {
        activate(connectors.injected);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!account || !mintWhenConnected) return;

    onClickMint();
    setMintWhenConnected(false);
  }, [account, mintWhenConnected]);

  const onClickMint = async () => {
    setIsMinting(true);

    try {
      if (!account) {
        setMintWhenConnected(true);
        await onClickConnect();
        return;
      }

      const balance = Number(balanceString) ?? 0;

      if (balance + quantity > MAX_PER_WALLET) {
        setShowLimitReachedModal(true);
        setIsMinting(false);
        return;
      }

      await switchToPolygon();
      const { txHash } = await mintNFT(quantity, account);

      if (txHash) {
        setShowSuccessModal(true);
        setTxHash(txHash);
      }
    } catch (error) {
      if (USE_TESTNET) console.log(error);
    }

    fetchDataFromSmartContract();
    setIsMinting(false);
  };

  const Superscript = ({ text = "" }) => (
    <sup className={Styles["sup"]}>{text}</sup>
  );

  const Countdown = () => (
    <div className={Styles["countdown"]}>
      <p className={Styles["countdown-title"]}>{`Available from ${new Date(
        MINT_DATE
      ).getDate()}/${new Date(MINT_DATE).getMonth() + 1}`}</p>

      <div className={Styles["countdown-time-left"]}>
        <p className="font-[Maax-Regular] mr-[15px] text-[12px] leading-[12px]">
          TIME <br />
          LEFT
        </p>
        <p className={Styles["countdown-digits"]}>
          {days}
          <Superscript text="D" /> : {hours}
          <Superscript text="H" /> : {minutes}
          <Superscript text="M" /> : {seconds}
          <Superscript text="S" />
        </p>
      </div>
    </div>
  );

  return (
    <div className="App">
      <Header showConnectBtn={!IS_SOLD_OUT} />
      {showCountdown ? <Countdown /> : null}
      <div className={Styles["container"]}>
        <div className={Styles["left-subcontainer"]}>
          <div className="mt-[14px]" />
          <Carousel />
        </div>

        <div className={Styles["right-subcontainer"]}>
          <p className="text-[#707682] mb-[14px] font-[Maax-Bold]">
            Mighty Jaxx
          </p>
          <p className="text-[#FFFFFF] text-[36px] mb-[35px] font-[Maax-Bold]">
            Sloggu Blindbox Collection
          </p>
          <p className="text-[#DCDFE6] text-[16px] font-normal mb-[50px] font-[Maax-Regular]">
            In the not-so-distant future, 8 different Sloggus sat in Slog tower,
            endlessly toiling away for all eternity, completing task after task
            without an end in sight. Their existence relegated to a miserable
            digital domain, they look to you, anon-fren, to help them escape -
            Save them by minting them for free! {MAX_SUPPLY} of these blockchain
            wage slaves are yours to rescue, help them now!
          </p>

          <div className={`${Styles["nft-details-container"]} mb-[30px]`}>
            <div className="mr-[15%]">
              <p className="font-[Maax-Bold]">Total quantity</p>
              <p className={Styles["nft-details-text"]}>{MAX_SUPPLY}</p>
            </div>
            <div>
              <p className="font-[Maax-Bold]">Available Now</p>
              <p className={Styles["nft-details-text"]}>
                {available >= 0 ? available : 0}
              </p>
            </div>
          </div>
          <div className={Styles["nft-details-container"]}>
            <div>
              <p className="font-[Maax-Bold]">Artist</p>
              <p className={Styles["nft-details-text"]}>Mighty Jaxx</p>
            </div>
          </div>
          <div className="w-full bg-[#22252E] h-[1px] mt-[50px] mb-[40px]"></div>

          <div className={Styles["nft-details-bottom-container"]}>
            <p className={Styles["gray-text"]}>QUANTITY</p>
            <div className="flex flex-row items-center border-[#22252E] border-[1px] p-[4px] pl-[18px] pr-[18px] text-[17px] text-[#FFFFFF]">
              <div
                className="text-[30px] pr-[17px] mt-[1px] font-normal cursor-pointer"
                onClick={() => changeQuantity(-1)}
              >
                <p>-</p>
              </div>
              <p className="pr-[17px]">{quantity}</p>
              <div
                className="text-[25px] font-normal mb-[2px] cursor-pointer"
                onClick={() => changeQuantity(1)}
              >
                <p>+</p>
              </div>
            </div>
          </div>
          <div className={Styles["nft-details-bottom-container"]}>
            <p className={Styles["gray-text"]}>PRICE</p>
            <p className="text-[22px] text-[#EFEFEF] font-[Maax-Bold]">FREE</p>
          </div>

          <button
            onClick={onClickMint}
            disabled={getState() !== "MINT NOW"}
            className={`flex flex-row  items-center justify-center text-[14px] font-[Maax-Bold] pt-[15px] pb-[15px] rounded-md ${
              getState() === "MINT NOW" || isMinting
                ? "bg-[#F7F7F7] text-[black"
                : "bg-[#353E4B] text-[white]"
            }`}
          >
            {getState()}
          </button>
          {txHash ? (
            <p className="text-[14px] font-bold text-white mt-[30px]">
              VIEW TRANSACTION ON{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3077FF]"
                href={polygonScanUrl}
              >
                POLYGON SCAN
              </a>
            </p>
          ) : null}
        </div>
      </div>

      <Footer />
      <MintSuccessModal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
      />
      <MintLimitModal
        isOpen={showLimitReachedModal}
        onRequestClose={() => setShowLimitReachedModal(false)}
      />
    </div>
  );
}

export default Sloggu;
