import { InjectedConnector } from "@web3-react/injected-connector";

const supportedChainIds = [1, 3, 4, 5, 42, 56, 97, 137, 43114, 80001];

const injected = new InjectedConnector({
  supportedChainIds,
});

export const connectors = {
  injected,
};
