import ReleasesStyles from "./Releases.module.css";
import CarouselSlider from "./UI/CarouselSlider";

// import UpcomingReleases from "../Constants/UpcomingReleases";
import RecentReleases from "../Constants/RecentReleases";

const Releases = () => {
  // const scrollRelease = (id: string) => {
  //   let elem = document.querySelector(`#${id}`);
  //   let rect = elem ? elem.getBoundingClientRect().top : 0;
  //   window.scrollTo({ top: rect + window.pageYOffset, behavior: "smooth" });
  // };
  return (
    <div id="app-releases">
      {/* <div className={ReleasesStyles["App-releases-navbar"]}>
        <ul className={ReleasesStyles["App-releases-nav-options"]}>
          <li className={ReleasesStyles["App-releases-nav-option"]}>
            <a
              className={ReleasesStyles.active}
              onClick={() => scrollRelease("app-releases")}
            >
              RELEASES
            </a>
          </li>
          <li className={ReleasesStyles["App-releases-nav-option"]}>
            <a onClick={() => scrollRelease("app-whitepaper")}>WHITEPAPER</a>
          </li>
          <li className={ReleasesStyles["App-releases-nav-option"]}>
            <a onClick={() => scrollRelease("app-roadmap")}>ROADMAP</a>
          </li>
          <li className={ReleasesStyles["App-releases-nav-option"]}>
            <a onClick={() => scrollRelease("app-faq")}>FAQ</a>
          </li>
          <li className={ReleasesStyles["App-releases-nav-option"]}>
            <a onClick={() => scrollRelease("footer")}>SOCIALS</a>
          </li>
        </ul>
      </div> */}

      <div className={ReleasesStyles["App-releases-recent"]}>
        <h1 className={ReleasesStyles["App-releases-heading"]}>OUR RELEASES</h1>

        <CarouselSlider items={RecentReleases} />
      </div>
    </div>
  );
};

export default Releases;
