import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#22252E",
    padding: "40px",
    borderWidth: 0,
    width: "408px",
    maxWidth: "88%",
  },
  overlay: {
    background: "#000000b3",
    zIndex: 10,
  },
};

const PurchaseSuccessfulModal = ({
  isOpen = false,
  onRequestClose = () => {},
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    contentLabel=" Purchase Successful"
  >
    <p className="text-center font-[Maax-Bold] text-white text-[22px] leading-[28px]">
      Purchase Successful
    </p>
    <p className="mt-[10px] text-center font-[Maax-Regular] text-white text-[16px] leading-[22px]">
      Your digital artwork will appear in <br />
      your wallet soon
    </p>

    <button
      onClick={onRequestClose}
      className="mt-[16px] text-[14px] font-[Maax-Bold] bg-[#F7F7F7] w-full h-[40px] rounded-md"
    >
      OK
    </button>
  </Modal>
);

export default PurchaseSuccessfulModal;
