export const Assets = {
  get ryogoHomepageTeaser() {
    return require("./Ryogo/homepage_teaser.jpg");
  },
  get ryogoNft1() {
    return require("./Ryogo/nft1.jpg");
  },
  get ryogoNft2() {
    return require("./Ryogo/nft2.png");
  },
  get ryogoNft3() {
    return require("./Ryogo/nft3.png");
  },
  get cross() {
    return require("./Icons/cross.png");
  },
  get medusaGreyImage() {
    return require("./Nfts/Medusa/grey.jpg");
  },
  get medusaPurpleImage() {
    return require("./Nfts/Medusa/purple.jpg");
  },
  get medusaRedImage() {
    return require("./Nfts/Medusa/red.jpg");
  },
  get medusaYellowImage() {
    return require("./Nfts/Medusa/yellow.jpg");
  },
  get medusaGrey() {
    return require("./Nfts/Medusa/grey.mp4");
  },
  get medusaPurple() {
    return require("./Nfts/Medusa/purple.mp4");
  },
  get medusaRed() {
    return require("./Nfts/Medusa/red.mp4");
  },
  get medusaYellow() {
    return require("./Nfts/Medusa/yellow.mp4");
  },
  get medusaEnlargedDetail1() {
    return require("./MedusaFigurine/EnlargedDetail/1.jpg");
  },
  get medusaEnlargedDetail2() {
    return require("./MedusaFigurine/EnlargedDetail/2.jpg");
  },
  get medusaEnlargedDetail3() {
    return require("./MedusaFigurine/EnlargedDetail/3.jpg");
  },
  get medusaTurnarounds1() {
    return require("./MedusaFigurine/Turnarounds/1.png");
  },
  get medusaTurnarounds2() {
    return require("./MedusaFigurine/Turnarounds/2.png");
  },
  get medusaTurnarounds3() {
    return require("./MedusaFigurine/Turnarounds/3.png");
  },
  get medusaTurnarounds4() {
    return require("./MedusaFigurine/Turnarounds/4.png");
  },
  get medusaTurnarounds5() {
    return require("./MedusaFigurine/Turnarounds/5.png");
  },
  get medusaTurnarounds6() {
    return require("./MedusaFigurine/Turnarounds/6.png");
  },
  get medusaTurnarounds7() {
    return require("./MedusaFigurine/Turnarounds/7.png");
  },
  get medusaTurnarounds8() {
    return require("./MedusaFigurine/Turnarounds/8.png");
  },
  get medusaBanner() {
    return require("./Banners/Medusa-Banner.jpg");
  },
  get medusaBannerMobile() {
    return require("./Banners/Medusa-Banner-Mobile.jpg");
  },
  get bgImageNft() {
    return require("./Backgrounds/bg-image-nft.jpg");
  },
  get anxiety() {
    return require("./Nfts/Anxiety.jpeg");
  },
  get buddha() {
    return require("./Nfts/Buddha.jpeg");
  },
  get ca() {
    return require("./Nfts/C_A.jpeg");
  },
  get eater() {
    return require("./Nfts/Eater.jpeg");
  },
  get involution() {
    return require("./Nfts/Involution.jpeg");
  },
  get loaf() {
    return require("./Nfts/Loaf.jpeg");
  },
  get lyingDown() {
    return require("./Nfts/LyingDown.jpeg");
  },
  get scapegoat() {
    return require("./Nfts/Scapegoat.jpeg");
  },
  get mightymeta() {
    return require("./Icons/MightyMeta.png");
  },
  get mightymetaSmall() {
    return require("./Icons/MightyMeta_small.png");
  },
  get mightymetaWhite() {
    return require("./Icons/MightyMeta_white.png");
  },
  get discord() {
    return require("./Icons/discord.png");
  },
  get twitter() {
    return require("./Icons/twitter.png");
  },
  get discordBlue() {
    return require("./Icons/discord-blue.png");
  },
  get twitterBlue() {
    return require("./Icons/twitter-blue.png");
  },
  get down_arrow() {
    return require("./Icons/down_arrow.png");
  },
  get left_arrow() {
    return require("./Icons/left_arrow.png");
  },
  get right_arrow() {
    return require("./Icons/right_arrow.png");
  },
  get transparentRightArrow() {
    return require("./Icons/transparent-right-arrow.png");
  },
  get faq_right_arrow() {
    return require("./Icons/faq_arrow_button1.png");
  },
  get faq_down_arrow() {
    return require("./Icons/faq_arrow_button2.png");
  },
  get creepie_cutie() {
    return require("./Releases/creepy_cutie.png");
  },
  get rebellion() {
    return require("./Releases/rebellion.png");
  },
  get rebellion_2() {
    return require("./Releases/rebellion-2.png");
  },
  get huntress() {
    return require("./Releases/NFT_HappyEnding_TheHuntress_Artwork_20210406.jpg");
  },
  get nyan_kashi() {
    return require("./Releases/nyan-kashi.png");
  },
  get sloggu() {
    return require("./Releases/sloggu.jpg");
  },
  get medusa() {
    return require("./Releases/medusa.jpg");
  },
  get nyan_treat() {
    return require("./Releases/NFT_NyammyTreats_NyanSum_Post1_V01_20210802.jpg");
  },
  get inked_stories_eve() {
    return require("./Releases/inked_stories_eve_colorized_edition_nft_still_image.jpg");
  },
  get abel_octovan_beauty_rebellion() {
    return require("./Releases/Abell Octovan_Beauty of_Rebellion_NFT_Still_Image.jpg");
  },
};
