import { useNavigate } from "react-router-dom";

import { Assets } from "../../Assets";
import { Stage } from "../../Constants/global";
import CardStyles from "./Card.module.css";

interface CardProps {
  title: string;
  description: string;
  date: string;
  image: string;
  link: string;
  isRecent: boolean;
  stage: Stage;
}

const Card = ({
  title,
  description,
  date,
  image,
  link,
  isRecent,
  stage,
}: CardProps) => {
  const isComingSoon = stage === Stage.ComingSoon;
  const isSoldOut = stage === Stage.SoldOut;

  const MainButton = () => {
    const navigate = useNavigate();

    if (isSoldOut) {
      return (
        <div
          className={`${CardStyles["Card-button-unavailable"]} ${CardStyles["Card-button"]} `}
        >
          SOLD OUT
        </div>
      );
    }

    if (isComingSoon) {
      return (
        <button
          onClick={() => navigate(link)}
          className={`${CardStyles["Card-button-coming-soon"]} ${
            CardStyles["Card-button"]
          } ${link ? "" : "pointer-events-none	"}`}
        >
          COMING SOON
        </button>
      );
    }

    return (
      <a
        className={`${CardStyles["Card-button-available"]} ${CardStyles["Card-button"]}`}
        href={link}
      >
        BUY NOW
      </a>
    );
  };

  return (
    <div className={CardStyles["Card"]}>
      <a href={link} className={link ? "" : "pointer-events-none"}>
        <img
          src={image}
          alt="NFT collection"
          className={CardStyles["Card-image"]}
        />
      </a>
      <h3 className={CardStyles["Card-title"]}>{title}</h3>
      <div className={CardStyles["Card-description"]}>{description}</div>

      {isRecent ? (
        <div className={`flex ${isSoldOut ? "" : "justify-center"}`}>
          <MainButton />

          {!isSoldOut || isComingSoon ? null : (
            <a
              href={link}
              className={`${CardStyles["Card-button-view"]} ${CardStyles["Card-button"]} `}
            >
              VIEW
            </a>
          )}
        </div>
      ) : (
        <a href={link}>
          <div className={CardStyles["Card-button"]}>{date}</div>
        </a>
      )}
    </div>
  );
};

Card.defaultProps = {
  title: "Creepie Cuties",
  description: "We aim to connect creators and communities",
  date: "6 NOV - 11 NOV 2021",
  image: Assets.creepie_cutie,
  link: "https://mightymeta.world",
  isRecent: false,
};

export default Card;
