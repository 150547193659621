import useCountDown from "../hooks/useCountDown";
import MedusaStyles from "../Routes/Medusa.module.css";
import SlogguStyles from "../Routes/Sloggu.module.css";

const Superscript = ({ text = "" }) => (
  <sup className={MedusaStyles["sup"]}>{text}</sup>
);

const Countdown = ({ date = "" }) => {
  const [days, hours, minutes, seconds] = useCountDown(date);
  const showCountdown = days + hours + minutes + seconds > 0;

  if (!showCountdown) return null;

  return (
    <div>
      <div className="flex items-center justify-center bg-white h-[48px] w-full">
        <p className="font-[Maax-Bold] mt-[2px] uppercase text-[black] text-[16px]">
          AVAILABLE FOR ALLOWLIST USERS NOW
        </p>
      </div>
      <div className={MedusaStyles["countdown"]}>
        <p
          className={SlogguStyles["countdown-title"]}
        >{`public sale starts in`}</p>

        <div className={SlogguStyles["countdown-time-left"]}>
          <p className="font-[Maax-Regular] mr-[15px] text-[12px] leading-[12px]">
            TIME <br />
            LEFT
          </p>
          <p className={SlogguStyles["countdown-digits"]}>
            {days}
            <Superscript text="D" /> : {hours}
            <Superscript text="H" /> : {minutes}
            <Superscript text="M" /> : {seconds}
            <Superscript text="S" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
