import Link from "./Link";

const TermsAndConditionsCheckbox = ({
  checked = false,
  onChange = () => {},
}) => {
  return (
    <div className="flex flex-row">
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />
      </label>

      <p className="text-[#DCDFE6] text-[14px] font-normal mb-[20px] font-[Maax-Regular] leading-[22px] ml-[8px]">
        I have read & agree with the{" "}
        <Link
          text={"Terms of Service"}
          url="https://mightyjaxx.com/pages/terms-of-service"
        />
        ,{" "}
        <Link
          text={"Privacy Policy"}
          url="https://mightyjaxx.com/pages/privacy-policy"
        />
        {" & "}
        <Link
          text={"NFT Terms & Conditions"}
          url="https://mightyjaxx.zendesk.com/hc/en-us/articles/9279224275097"
        />{" "}
      </p>
    </div>
  );
};
export default TermsAndConditionsCheckbox;
