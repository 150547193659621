import Modal from "react-modal";
import { Assets } from "../../Assets";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#00000000",
    borderWidth: 0,
    maxHeight: "100vh",
    width: "100%",
    overflow: "auto",
  },
  overlay: {
    background: "#000000",
    zIndex: 10,
  },
};

const ProductImage = ({ src = "" }) => (
  <img
    src={src}
    alt={"Enlarged Detail"}
    className="w-[633px] max-w-[100%] mt-[32px] sm:mt-[48px]"
  />
);

const ProductImagesModal = ({ isOpen = false, onRequestClose = () => {} }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <button
      onClick={onRequestClose}
      className="absolute top-[24px] right-[23px] md:top-[63px] md:right-[40px] text-[white] text-[50px] h-[16px] w-[16px] md:h-[28px] md:w-[28px]"
    >
      <img src={Assets.cross} alt={"Close modal"} />
    </button>
    <div className="flex flex-col items-center mb-[50px] md:mb-0 mt-[20px] md:mt-0">
      <ProductImage src={Assets.medusaEnlargedDetail1} />
      <ProductImage src={Assets.medusaEnlargedDetail2} />
      <ProductImage src={Assets.medusaEnlargedDetail3} />
    </div>
  </Modal>
);

export default ProductImagesModal;
