import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import Releases from "../Components/Releases";
import Welcome from "../Components/Welcome";

import "./Home.css";

function Home() {
  return (
    <div className="App">
      <Header />
      <div className="App-content">
        <Welcome />
        <div
          style={{
            background:
              "linear-gradient(360deg, rgba(6, 9, 18, 1) 0%, rgba(122, 19, 136, 1) 27%, #373FA3, rgba(6, 9, 18, 1) 100%)",
          }}
        >
          <Releases />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
