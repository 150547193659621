import MedusaStyles from "../../Routes/Medusa.module.css";
import Styles from "../../Routes/Sloggu.module.css";

const NftDetails = ({
  isSoldOut = false,
  available = 0,
  maxSupply = 0,
  artist = "",
  exclusiveRights = "",
}) => {
  return (
    <>
      {isSoldOut ? null : (
        <div className={`${MedusaStyles["nft-details-container"]} mb-[30px]`}>
          <div className="w-[190px] mb-[30px] md:mb-0">
            <p className="font-[Maax-Bold]">Allowlist Price</p>
            <p className={Styles["nft-details-text"]}>89 USDC</p>
          </div>
          <div>
            <p className="font-[Maax-Bold]">Public Sale Price</p>
            <p className={Styles["nft-details-text"]}>99 USDC</p>
          </div>
        </div>
      )}

      {isSoldOut ? null : (
        <div className={`${MedusaStyles["nft-details-container"]} mb-[30px]`}>
          <div className="w-[190px] mb-[30px] md:mb-0">
            <p className="font-[Maax-Bold]">Total quantity</p>
            <p className={Styles["nft-details-text"]}>{maxSupply}</p>
          </div>
          <div>
            <p className="font-[Maax-Bold]">Available Now</p>
            <p className={Styles["nft-details-text"]}>
              {available >= 0 ? available : 0}
            </p>
          </div>
        </div>
      )}

      <div className={`${MedusaStyles["nft-details-container"]}`}>
        <div className="w-[190px] mb-[30px] md:mb-0">
          <p className="font-[Maax-Bold]">Artist</p>
          <p className={Styles["nft-details-text"]}>{artist}</p>
        </div>
        <div>
          <p className="font-[Maax-Bold]">Exclusive rights</p>
          <p className={Styles["nft-details-text"]}>{exclusiveRights}</p>
        </div>
      </div>
      <div className="w-full bg-[#22252E] h-[1px] mt-[35px] mb-[35px]" />
    </>
  );
};

export default NftDetails;
