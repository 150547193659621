/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Footer from "../Components/Common/Footer";
import Header from "../Components/Common/Header";
import Styles from "./Sloggu.module.css";
import MedusaStyles from "./Medusa.module.css";
import Carousel from "../Components/Carousel";
import { MEDUSA, Stage } from "../Constants/global";
import { getTotalSupply } from "../Helpers/web3";
import { getItem, isStringNumeric } from "../Helpers/utils";
import PurchaseSuccessfulModal from "../Components/Modals/PurchaseSuccessfulModal";
import Countdown from "../Components/Countdown";
import TermsAndConditionsCheckbox from "../Components/UI/TermsAndConditionsCheckbox";
import { Assets } from "../Assets";
import ProductImagesModal from "../Components/Modals/ProductImagesModal";
import PhysicalFigurine from "../Components/ProductDetail/PhysicalFigurine";
import CustomButton from "../Components/UI/CustomButton";
import BuyButton from "../Components/UI/BuyButton";
import NftDetails from "../Components/UI/NftDetails";

const {
  reserved,
  date,
  maxSupply,
  title,
  hasQuantitySelector,
  presaleLink,
  publicSaleLink,
  preSalePrice,
  maxPerWallet,
  banner,
  publicPrice,
  bannerMobile,
  stage,
  abi,
  contractAddress,
  physicalFigurineLink,
} = MEDUSA;

const Medusa = () => {
  const isComingSoon = stage === Stage.ComingSoon;
  const isSoldOut = stage === Stage.SoldOut;
  const isAvailable = stage === Stage.Available;

  const [quantity, setQuantity] = useState(1);
  const initialStateMinted = getItem(`minted_${title}`);
  const [mintedString, setMinted] = useState(
    isSoldOut ? maxSupply : initialStateMinted
  );
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hasClickedOnImage, setHasClickedOnImage] = useState(false);
  const [tncChecked, setTncChecked] = useState(false);
  const [searchParams] = useSearchParams();

  const minted = Number(mintedString);
  const available = maxSupply - minted - reserved;
  const showCountdown = new Date(date) > new Date() && isAvailable;
  const isPublicSale = (!showCountdown && isAvailable) || isSoldOut;
  const price = isPublicSale ? publicPrice : preSalePrice;
  const paperLink = isPublicSale ? publicSaleLink : presaleLink;

  const getState = () => {
    if (minted === maxSupply || isSoldOut) {
      return "CLOSED";
    }

    if (isComingSoon) {
      return "COMING SOON";
    }

    return "MINT NOW";
  };

  const state = getState();

  useEffect(() => {
    fetchDataFromSmartContract();
  }, []);

  useEffect(() => {
    const showSuccessModal = searchParams.get("success") === "true";
    setShowSuccessModal(showSuccessModal);
  }, [searchParams]);

  const fetchDataFromSmartContract = () => {
    if (state === "MINT NOW") {
      getTotalSupply(abi, contractAddress).then((minted) => {
        if (isStringNumeric(minted)) {
          setMinted(minted);
          window.localStorage.setItem(`minted_${title}`, minted);
        }
      });
    }
  };

  const changeQuantity = (change: number) => {
    setQuantity((value) => {
      const MIN = 1;
      const MAX = maxPerWallet;

      if ((value <= MIN && change < 0) || (value >= MAX && change > 0)) {
        return value;
      }

      return value + change;
    });
  };

  const Description = ({
    text = "",
    hasLargePaddingTop = false,
    hasLeftPadding = false,
  }) => (
    <p
      className={`text-[#DCDFE6] text-[16px] font-normal font-[Maax-Regular] leading-[22px] ${
        hasLargePaddingTop ? "pt-[20px] md:pt-[30px]" : "pt-[9px]"
      }
      ${hasLeftPadding ? "pl-[10px]" : ""}
     `}
    >
      {text}
    </p>
  );

  const DetailImage = ({ src = "" }) => (
    <>
      <img src={src} alt="medusa figurine detail" height={80} width={80} />
      <div className="mb-[8px]" />
    </>
  );

  const DetailImageMobile = ({ src = "" }) => (
    <img
      src={src}
      alt="Product Detail"
      height={128}
      width={128}
      className={Styles["nft-image"]}
      onClick={() => setHasClickedOnImage(true)}
    />
  );

  const videos: any[] = [
    Assets.medusaGrey,
    Assets.medusaPurple,
    Assets.medusaRed,
    Assets.medusaYellow,
  ];

  const nftImages = [
    Assets.medusaGreyImage,
    Assets.medusaPurpleImage,
    Assets.medusaRedImage,
    Assets.medusaYellowImage,
  ];

  return (
    <div className="App">
      <Header />
      {showCountdown ? <Countdown date={date} /> : null}
      <div className={`pb-[65px] ${MedusaStyles["container"]}`}>
        <div className={Styles["left-subcontainer"]}>
          <Carousel assetList={nftImages} videosList={videos} />
        </div>

        <div className={MedusaStyles["right-subcontainer"]}>
          <p className="text-[#707682] mb-[14px] font-[Maax-Bold]">
            Mighty Jaxx
          </p>
          <p className="text-[#FFFFFF] text-[36px] font-[Maax-Bold] leading-[44px]">
            {title}
          </p>

          <div className="mb-[40px]">
            <Description
              hasLargePaddingTop
              text={`The Greek tales of old chronicle Medusa as a monstrous beast, hair
              of snakes. Yet, some recounts describe her as an alluring beauty.
              Her enigmatic nature continues with a name which means “guardian”,
              but a reputation that’s quite the opposite, striking fear into
              even the bravest of heroes.`}
            />
            <Description
              hasLargePaddingTop
              text={`With a gaze that can petrify any living creature that falls prey,
              it’s no surprise that her physical appearance remains contentious.
              The mysterious Gorgon comes to life in this collaborative piece by
              JPK and Mighty Jaxx.`}
            />
            <Description
              hasLargePaddingTop
              text={`Taking a two-prong approach in the reimagining, Mighty Jaxx
              designed and sculpted the Medusa figure with a hair full of
              snakes. JPK works his magic, highlighting her story through a
              series of full body tattoos; her lover, Poseidon, slayer,Perseus,
              and common depictions in historical artefacts. This is the secret
              of Medusa, unveiled.`}
            />
          </div>

          <NftDetails
            isSoldOut={isSoldOut}
            available={available}
            maxSupply={maxSupply}
            artist={"Mighty Jaxx"}
            exclusiveRights={"Physical product"}
          />

          {isComingSoon || !hasQuantitySelector ? null : (
            <div className={Styles["nft-details-bottom-container"]}>
              <p className={Styles["gray-text"]}>QUANTITY</p>
              <div className="flex flex-row items-center border-[#22252E] border-[1px] p-[4px] pl-[18px] pr-[18px] text-[17px] text-[#FFFFFF]">
                <div
                  className="text-[30px] pr-[17px] mt-[1px] font-normal cursor-pointer"
                  onClick={() => changeQuantity(-1)}
                >
                  <p>-</p>
                </div>
                <p className="pr-[17px]">{quantity}</p>
                <div
                  className="text-[25px] font-normal mb-[2px] cursor-pointer"
                  onClick={() => changeQuantity(1)}
                >
                  <p>+</p>
                </div>
              </div>
            </div>
          )}
          <div className={Styles["nft-details-bottom-container"]}>
            <p className={Styles["gray-text"]}>PRICE</p>
            <div className="flex justify-center items-center ">
              <p className="text-[22px] text-[#EFEFEF] font-[Maax-Bold]">
                ${price * quantity} USDC*{" "}
              </p>

              <span className="inline-flex justify-center items-center bg-[#353E4B] rounded-[12px] w-[24px] h-[24px] text-center ml-[7px] mb-[5px] tooltip">
                <p className="text-[16px] text-white mt-[2px]">?</p>
                <span className="tooltiptext">
                  You can pay by Credit Card, Apple Pay(Safari), Google
                  Pay(Chrome) or Ethereum upon checkout
                </span>
              </span>
            </div>
          </div>

          {isAvailable ? (
            <TermsAndConditionsCheckbox
              checked={tncChecked}
              onChange={() => setTncChecked(!tncChecked)}
            />
          ) : null}

          <BuyButton
            isSoldOut={isSoldOut}
            isComingSoon={isComingSoon}
            tncChecked={tncChecked}
            paperLink={paperLink}
            isPublicSale={isPublicSale}
          />
        </div>
      </div>

      <div className="pb-[64px] bg-[#000000]">
        <img
          src={banner}
          alt={"Banner"}
          className="hidden md:inline-block"
          width={"100%"}
        />
        <img
          src={bannerMobile}
          alt={"Banner"}
          className="md:hidden"
          width={"100%"}
        />
      </div>

      <div className={`pb-[50px] desktop:pb-0 ${MedusaStyles["container"]}`}>
        <div
          className={`flex flex-col desktop:flex-row flex-auto justify-center desktop:justify-start desktop:w-[450px] items-center desktop:ml-[1%] desktop:mt-[20px] mobile:mb-[120px] mb-[15px]`}
        >
          <button
            className={`flex-col absolute mb-[40px] hidden desktop:flex`}
            onClick={() => setHasClickedOnImage(true)}
          >
            <DetailImage src={Assets.medusaEnlargedDetail1} />
            <DetailImage src={Assets.medusaEnlargedDetail2} />
            <DetailImage src={Assets.medusaEnlargedDetail3} />
          </button>

          <PhysicalFigurine />

          <div
            className={`hidden ${MedusaStyles["carousel-container-mobile"]}`}
          >
            <DetailImageMobile src={Assets.medusaEnlargedDetail1} />
            <DetailImageMobile src={Assets.medusaEnlargedDetail2} />
            <DetailImageMobile src={Assets.medusaEnlargedDetail3} />
          </div>
        </div>

        <div className={MedusaStyles["right-subcontainer"]}>
          <p className="text-[#FFFFFF] text-[32px] font-[Maax-Bold] leading-[38px]">
            {physicalFigurineLink ? (
              "MEDUSA PHYSICAL FIGURINE"
            ) : (
              <>
                PHYSICAL FIGURINE <br /> COMING SOON
              </>
            )}
          </p>
          <div className="mb-[28px]">
            <Description
              hasLargePaddingTop
              text={`NFT owners have EXCLUSIVE access to the physical Medusa figurine. Sculpted by Mighty Jaxx, you’ll be able to examine every story-inspired tattoo in real life! Stay tuned for updates on the pre-order launch.`}
            />
          </div>
          <div className="ml-[10px] mb-[40px] max-w-[300px]">
            <Description text={`• 8.3" Polystone Art Toy Limited Edition`} />
            <Description text={`• Powered by Forreal`} />
            <Description text={`• Free Shipping Worldwide`} />
            <Description text={`• Exclusively available to Inked Stories: `} />
            <Description hasLeftPadding text={`Medusa NFT holders`} />
          </div>
          {physicalFigurineLink ? (
            <CustomButton
              href={physicalFigurineLink}
              isDisabled={false}
              text={"available now"}
            />
          ) : (
            <CustomButton href="" isDisabled text={"COMING SOON"} />
          )}
        </div>
      </div>

      <Footer />
      <PurchaseSuccessfulModal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
      />
      <ProductImagesModal
        isOpen={hasClickedOnImage}
        onRequestClose={() => setHasClickedOnImage(false)}
      />
    </div>
  );
};

export default Medusa;
