import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import CustomModal from "./Common/CustomModal";

interface formikValues {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

const ContactUsView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const Schema = Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name."),
    lastName: Yup.string().required("Please enter your last name."),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email."),
    subject: Yup.string().required("Please select one subject."),
    message: Yup.string().required("Please enter your message."),
  });

  const submitForm = async (values: formikValues) => {
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_MJ_SERVER_BASE_URL}/api/v4/contact-us`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        }
      );

      if (!res.ok) throw res.statusText;

      setModalTitle("Enquiry Submitted!");
      setModalMessage(
        "Thanks for submitting your enquiry. Our team will get back to you soon."
      );
      formik.resetForm();
    } catch (error: any) {
      setModalTitle("Submission Failed");
      setModalMessage(
        error.message || "Something Went Wrong! Please try again later."
      );
    } finally {
      setIsModalOpen(true);
    }
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const formik = useFormik<formikValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
    },
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: submitForm,
  });

  return (
    <>
      <Box
        background="black"
        py={["40px", "64px"]}
        display={["block", "flex"]}
        justifyContent={"center"}
      >
        {/* <Center> */}
        <Box maxW="450px" mx={25}>
          <form onSubmit={formik.handleSubmit}>
            <Text
              color="#BFC8BD"
              fontWeight={700}
              fontSize="28px"
              lineHeight="28px"
              textAlign="left"
              mb="34px"
            >
              Contact Us
            </Text>

            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
              gap="24px"
            >
              <FormControl className="mr-4">
                <FormLabel fontWeight={500} fontSize={14} color="white">
                  First name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="firstName"
                  name="firstName"
                  variant="filled"
                  background="#13161A"
                  color="white"
                  isInvalid={
                    formik.touched.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  _hover={{ background: "#13161A" }}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <Text textAlign="left" color="red.500" my="10px">
                    {formik.errors.firstName}
                  </Text>
                )}
              </FormControl>

              <FormControl className="mr-4">
                <FormLabel fontWeight={500} fontSize={14} color="white">
                  Last name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="lastName"
                  name="lastName"
                  variant="filled"
                  background="#13161A"
                  color="white"
                  isInvalid={
                    formik.touched.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  _hover={{ background: "#13161A" }}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <Text textAlign="left" color="red.500" my="10px">
                    {formik.errors.lastName}
                  </Text>
                )}
              </FormControl>
            </Grid>

            <FormControl mt="18px">
              <FormLabel fontWeight={500} fontSize={14} color="white">
                Email <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                variant="filled"
                background="#13161A"
                color="white"
                isInvalid={
                  formik.touched.email && formik.errors.email ? true : false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                _hover={{ background: "#13161A" }}
              />
              {formik.touched.email && formik.errors.email && (
                <Text textAlign="left" color="red.500" my="10px">
                  {formik.errors.email}
                </Text>
              )}
            </FormControl>

            <FormControl mt="18px">
              <FormLabel fontWeight={500} fontSize={14} color="white">
                Subject <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Select
                id="subject"
                name="subject"
                value={formik.values.subject}
                h="40px"
                variant="filled"
                background="#13161A"
                color="white"
                isInvalid={
                  formik.touched.subject && formik.errors.subject ? true : false
                }
                onChange={formik.handleChange}
                _hover={{ background: "#13161A" }}
              >
                <option value="">Select One</option>
                <option value="Customer Service">Customer Service</option>
                <option value="Other Enquiries">Other Enquiries</option>
              </Select>
              {formik.touched.subject && formik.errors.subject && (
                <Text textAlign="left" color="red.500" my="10px">
                  {formik.errors.subject}
                </Text>
              )}
            </FormControl>

            <FormControl mt="18px">
              <FormLabel fontWeight={500} fontSize={14} color="white">
                Message <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Textarea
                id="message"
                name="message"
                variant="filled"
                background="#13161A"
                color="white"
                isInvalid={
                  formik.touched.message && formik.errors.message ? true : false
                }
                size="lg"
                rows={5}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                _hover={{ background: "#13161A" }}
              />
              {formik.touched.message && formik.errors.message && (
                <Text textAlign="left" color="red.500" my="10px">
                  {formik.errors.message}
                </Text>
              )}
            </FormControl>

            <Box mt="24px" textAlign="left" mb="56px">
              <Button
                background="white"
                h="40px"
                w="144px"
                alignSelf="left"
                type="submit"
              >
                <Text
                  fontWeight={700}
                  fontSize={12}
                  lineHeight={0}
                  textTransform="uppercase"
                >
                  Send
                </Text>
              </Button>
            </Box>
          </form>
        </Box>
        {/* </Center> */}
      </Box>
      <CustomModal
        isOpen={isModalOpen}
        title={modalTitle}
        message={modalMessage}
        primaryLabel="Okay"
        onClose={onCloseModal}
        onClickPrimary={onCloseModal}
      />
    </>
  );
};

export default ContactUsView;
