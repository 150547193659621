import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#22252E",
    padding: "40px",
    borderWidth: 0,
  },
  overlay: {
    background: "#000000b3",
    zIndex: 10,
  },
};

const MintSuccessModal = ({ isOpen = false, onRequestClose = () => {} }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    contentLabel="Successfully minted a collectible"
  >
    <p className="text-center font-[Maax-Bold] text-white text-[22px] leading-[26px]">
      You have <br /> successfully <br /> minted a collectible
    </p>
    <button
      onClick={onRequestClose}
      className="mt-[5px] text-[14px] font-[Maax-Bold] bg-[#F7F7F7] w-full h-[40px] rounded-md"
    >
      OK
    </button>
  </Modal>
);

export default MintSuccessModal;
