import WelcomeStyles from "./Welcome.module.css";

const Welcome = () => (
  <div className={WelcomeStyles["App-welcome"]}>
    <h3 className={WelcomeStyles["App-welcome-title"]}>ENTER THE</h3>

    <h1 className={WelcomeStyles["App-welcome-mightymeta"]}>MIGHTY VERSE</h1>

    <p className={WelcomeStyles["App-welcome-intro"]}>
      Immerse yourself in phygital experiences brought about by the next
      generation creators economy
    </p>
  </div>
);

export default Welcome;
