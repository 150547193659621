import { Assets } from "../../Assets";
import MobileMenu from "./MobileMenu.module.css";

import { useState } from "react";
import HeaderLinks from "../../Constants/HeaderLinks";
import SocialLinks from "../../Constants/SocialLinks";

const MobileMenuComponent = () => {
  const [mightyMeta, toggleMightyMeta] = useState(false);
  interface ItemProps {
    text: string;
    href?: string;
    chevron?: boolean;
    backStyling?: boolean;
    onClick?: () => void;
  }

  const Item = ({
    text = "",
    href = "#",
    chevron = false,
    backStyling = false,
    onClick,
  }: ItemProps) => (
    <a
      className={`
pl-[35px] pr-[35px] h-[72px]  text-[16px] border-b-2 border-gray-900 flex flex-row w-full items-center
      ${
        backStyling
          ? "justify-start font-[Maax]"
          : "justify-between font-[Maax-Bold]"
      }`}
      href={href}
      onClick={onClick}
    >
      {backStyling ? <span className="chevron left pl-[10px] "></span> : null}
      <p
        className={`${
          backStyling
            ? " text-gray-500 text-sm pl-[10px] font-medium "
            : "text-white"
        }`}
      >
        {text}
      </p>
      {chevron ? <span className="chevron right"></span> : null}
    </a>
  );

  return (
    <div className={`${MobileMenu["App-header-nav-menu"]} hide-in-desktop`}>
      {mightyMeta ? (
        <>
          <Item
            text="BACK"
            backStyling
            onClick={() => toggleMightyMeta(!mightyMeta)}
          />
          <Item text="TWITTER" href={SocialLinks.twitter} />
          <Item text="DISCORD" href={SocialLinks.discord} />
        </>
      ) : (
        <>
          <Item text="MIGHTY JAXX" href={HeaderLinks.mightyjaxx} chevron />
          <Item
            text="MIGHTY META"
            chevron
            onClick={() => toggleMightyMeta(!mightyMeta)}
          />
        </>
      )}

      <div className={MobileMenu["App-header-nav-social"]}>
        <h2 className={MobileMenu["App-header-nav-social-title"]}>
          Follow our socials
        </h2>
        <div className={MobileMenu["App-header-nav-social-bar"]}>
          <a href={SocialLinks.twitter}>
            <img
              src={Assets.twitterBlue}
              alt="Twitter"
              style={{
                width: SocialLinks.iconSize,
                height: SocialLinks.iconSize,
              }}
            />
          </a>
          <a href={SocialLinks.discord} className="pl-[25px]">
            <img
              src={Assets.discordBlue}
              alt="Discord"
              style={{
                width: SocialLinks.iconSize,
                height: "45px",
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenuComponent;
