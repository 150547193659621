import { Assets } from "../../Assets";

import SocialLinks from "../../Constants/SocialLinks";
import Styles from "./Footer.module.css";

const Copyright = ({ hideInMobile = false }) => (
  <p
    className={`text-[#DCDFE6] text-[12px] sm:text-[14px] font-[Maax-Medium] ${
      hideInMobile ? "hide-in-mobile" : "hide-in-desktop"
    }`}
  >
    © MIGHTY META
  </p>
);

const Footer = () => (
  <div className={Styles["container"]}>
    <div className="flex flex-col flex-1">
      <p className="font-[Maax-Black] text-[#F7F7F7] text-[36px] sm:text-[54px] leading-[42px] sm:leading-[59px] mb-[28px]">
        JOIN OUR
        <br /> COMMUNITY
        <br /> TODAY!
      </p>

      <div className="flex flex-row mb-[75px]">
        <a href={SocialLinks.twitter}>
          <img
            src={Assets.twitterBlue}
            alt="Twitter"
            style={{
              width: SocialLinks.iconSize,
              height: SocialLinks.iconSize,
            }}
          />
        </a>
        <a href={SocialLinks.discord} className="pl-[25px]">
          <img
            src={Assets.discordBlue}
            alt="Discord"
            style={{
              width: SocialLinks.iconSize,
              height: "45px",
            }}
          />
        </a>
      </div>
      <Copyright hideInMobile />
    </div>
    <div className={Styles["email-form-container"]}>
      <p className="text-[#F7F7F7] text-[18px] mb-[5px] font-[Maax-Medium]">
        Sign up to receive exclusive news!
      </p>
      {process.env.NODE_ENV === "production" ? (
        <div className={`klaviyo-form-Tzicp6 ${Styles["email-form"]}`}></div>
      ) : null}
      <p className={Styles["email-text"]}>
        By submitting your email address, you are subscribing to receive updates
        on exclusive drops and news
      </p>

      <Copyright />
    </div>
  </div>
);

export default Footer;
