import { memo, useState } from "react";
import { Assets } from "../Assets/index";
import Styles from "../Routes/Sloggu.module.css";

const defaultAssetList = [
  Assets.anxiety,
  Assets.buddha,
  Assets.ca,
  Assets.eater,
  Assets.involution,
  Assets.loaf,
  Assets.lyingDown,
  Assets.scapegoat,
];

const NftImage = ({
  index = 0,
  src = "",
  onClickImage,
}: {
  index: number;
  src: string;
  onClickImage: (index: number) => void;
}) => (
  <img
    src={src}
    alt="NFT"
    height={96}
    width={96}
    className={Styles["nft-image"]}
    onClick={() => onClickImage(index)}
  />
);

const MemoNftImage = memo(NftImage);

const Carousel = ({
  assetList = defaultAssetList,
  videosList = [],
}: {
  assetList?: any;
  videosList?: any;
}) => {
  const [nfts, setNfts] = useState(assetList);
  const [videos, setVideos] = useState(videosList);
  const [selected, setSelected] = useState(
    videos.length ? videos[0] : assetList[0]
  );
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const getAssetFromIndex = (index: number) => {
    return videos.length ? videos[index] : nfts[index];
  };

  const onClickArrow = (direction: string) => {
    const rearrangeAssets = (assets: any) => {
      const firstIndex = 0;
      const lastIndex = assets.length - 1;
      const firstItem = assets[firstIndex];
      const lastItem = assets[lastIndex];

      const result = assets.map((_: any, index: number) => {
        if (index === lastIndex && direction === "right") return firstItem;
        else if (index === firstIndex && direction === "left") return lastItem;

        const change = direction === "right" ? 1 : -1;
        return assets[index + change];
      });

      return result;
    };

    const rearrangedNfts = rearrangeAssets(nfts);
    const rearrangedVideos = rearrangeAssets(videos);

    setNfts(rearrangedNfts);
    setVideos(rearrangedVideos);

    setSelected(videos.length ? rearrangedVideos[0] : rearrangedNfts[0]);
    setIsVideoLoaded(false);
  };

  const onClickImage = (index: number) => {
    setSelected(getAssetFromIndex(index));
    setIsVideoLoaded(false);
  };

  const Arrow = ({
    direction = "left",
    onClick = (direction: string) => {},
  }) => (
    <div
      onClick={() => onClick(direction)}
      className={`flex items-center justify-center bg-[#13161A] w-[24px] h-[24px] cursor-pointer ${
        direction === "left" ? "mr-[2px]" : "ml-[2px]"
      } `}
    >
      <div className={Styles[`arrow-${direction}`]}></div>
    </div>
  );

  return (
    <>
      <div className={Styles[`main-nft`]}>
        {videos.length ? (
          <video
            autoPlay
            controls={false}
            controlsList="nodownload"
            loop
            playsInline
            muted
            webkit-playsinline
            preload="auto"
            src={selected}
            data-black-overlay="6"
            poster={nfts[0]}
          />
        ) : (
          <img
            src={selected}
            alt="Selected NFT"
            className={Styles[`main-nft-image`]}
          />
        )}
      </div>

      {assetList.length > 1 ? (
        <>
          <div className={Styles["carousel-container-desktop"]}>
            <Arrow direction="left" onClick={onClickArrow} />
            <MemoNftImage index={0} src={nfts[0]} onClickImage={onClickImage} />
            <MemoNftImage index={1} src={nfts[1]} onClickImage={onClickImage} />
            <MemoNftImage index={2} src={nfts[2]} onClickImage={onClickImage} />
            {assetList.length > 3 ? (
              <MemoNftImage
                index={3}
                src={nfts[3]}
                onClickImage={onClickImage}
              />
            ) : null}
            <Arrow direction="right" onClick={onClickArrow} />
          </div>

          <div className={Styles["carousel-container-mobile"]}>
            {nfts.map((_: any, index: number) => (
              <div key={index.toString()} className={Styles[`image-container`]}>
                <MemoNftImage
                  index={index}
                  src={nfts[index]}
                  onClickImage={onClickImage}
                />
              </div>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};

export default memo(Carousel);
